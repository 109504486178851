import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NotFoundPage = () => (
    <Layout>
        <SEO title="Aguaman - 404" pathname="/404" />
        <section className="notfound-prop">
            <div className="notfound-prop-content">
                <div className="notfound-photo"></div>
                <div className="notfound-message">
                    <h1>404</h1>
                    <h3 className="notfound-subtitle">Esta página no existe</h3>
                    <div>
                        <a className="button">Ir al Inicio</a>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default NotFoundPage;
